<template>
  <v-card>
    <v-card-title v-if="this.$route.params.id">
      {{ $t(' teachers ') }} {{ this.school.name }}
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#098064"
            dark
            small
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="exportExcelById($route.params.id)"
          >
            {{ $t('exportAll') }}
            <v-icon
              dark
              right
            >
              mdi-file-excel
            </v-icon>
          </v-btn>
        </template>
        <span>  تصدير مجموعة ارقام  </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="info"
            dark
            small
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="sentAll()"
          >
            {{ $t('sentAll') }}
            <v-icon
              dark
              right
            >
              mdi-send
            </v-icon>
          </v-btn>
        </template>
        <span> ارسال كل ارقام </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="info"
            dark
            small
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="sentSelected()"
          >
            {{ $t('sentSelected') }}
            <v-icon
              dark
              right
            >
              mdi-send
            </v-icon>
          </v-btn>
        </template>
        <span>  ارسال مجموعة ارقام  </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            dark
            small
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="activeSelected()"
          >
            {{ $t('activeSelected') }}
            <v-icon
              dark
              right
            >
              mdi-power
            </v-icon>
          </v-btn>
        </template>
        <span>  تفعيل مجموعة ارقام  </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            dark
            small
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="activeAll()"
          >
            {{ $t('activateAll') }}
            <v-icon
              dark
              right
            >
              mdi-power
            </v-icon>
          </v-btn>
        </template>
        <span>  تفعيل مجموعة ارقام  </span>
      </v-tooltip>
    </v-card-title>
    <v-card-title v-else>
      {{ $t('teachers') }}
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            small
            color="#098064"
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="exportExcel()"
          >
            {{ $t('exportAll') }}
            <v-icon
              dark
              right
            >
              mdi-file-excel
            </v-icon>
          </v-btn>
        </template>
        <span> تصدير الارقام </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="info"
            dark
            small
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="sentSelected()"
          >
            {{ $t('sentSelected') }}
            <v-icon
              dark
              right
            >
              mdi-send
            </v-icon>
          </v-btn>
        </template>
        <span> ارسال مجموعة ارقام المحددة </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="info"
            dark
            small
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="sentAll()"
          >
            {{ $t('sentAll') }}
            <v-icon
              dark
              right
            >
              mdi-send
            </v-icon>
          </v-btn>
        </template>
        <span> ارسال كل ارقام </span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            dark
            small
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="activeSelected()"
          >
            {{ $t('activeSelected') }}
            <v-icon
              dark
              right
            >
              mdi-power
            </v-icon>
          </v-btn>
        </template>
        <span> تفعيل مجموعة الارقام المحددة </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            dark
            small
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click.prevent="activeAll()"
          >
            {{ $t('activateAll') }}
            <v-icon
              dark
              right
            >
              mdi-power
            </v-icon>
          </v-btn>
        </template>
        <span> تفعيل جميع ارقام </span>
      </v-tooltip>
    </v-card-title>

    <v-data-table
      ref="table"
      v-model="selected"
      class="dd"
      :headers="headers"
      :items="teachers"
      :options.sync="options"
      show-select
      :server-items-length="teachersTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50, 100, 500],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :table="$refs.table" :props="props" :options.sync="options" :translation-key="translationKey"
                          @update:filters="fetch"></datatable-header>
      </template>

      <template v-slot:header.actions="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:item.free="{ item }">
        <v-btn
          x-small
          outlined
          :color="item.free ? 'success' : 'error'"
        >
          {{ item.free ? 'مجاني' : 'مدفوع ' }}
        </v-btn>
      </template>
      <template v-slot:item.school_name="{ item }">
        <v-chip
          color='info'
          small
          @click='showNumbersSchool(item.school_id)'
        >
          {{ item.school_name }}
        </v-chip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-btn
          v-if='item.sent && !item.active && !item.locked'
          color="info"
          label
          x-small
        >
          مرسل
        </v-btn>

        <v-btn
          v-else-if='item.sent && item.active && !item.locked'
          color="success"
          label
          x-small
        >
          مفعل
        </v-btn>

        <v-btn
          v-else-if='item.locked'
          color="error"
          label
          x-small
        >
          مرفوض
        </v-btn>

        <v-btn
          v-else
          color="#0e62cf"
          label
          style='color: white'
          x-small
        >
          جديد
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mx-2"
          color="info"
          x-small
          :disabled="!!item.sent"
          @click="sentFunction(item)"
        >
          {{ $('sent') }}
          <v-icon
            dark
            right
          >
            mdi-send
          </v-icon>
        </v-btn>

        <v-btn
          class="mx-2"
          color="success"
          :disabled="!!item.active"
          x-small
          @click="activateFunction( item)"
        >
          {{ $('activate') }}
          <v-icon
            dark
            right
          >
            mdi-power
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          color="error"
          :disabled="!!item.locked"
          x-small
          @click="dialogModal(true, 'update', item.id)"
        >
          {{ $('locked') }}
          <v-icon
            dark
            right
          >
            mdi-null
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <teacher-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></teacher-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import {ObjectToQuery} from '@/plugins/helper';
import {getFilters, getOptions} from '@/components/dataTables/helper'
import toast from '@/store/toast'
import statistics from '@/store/statistics'
import TeacherDialog from '@/views/pages/teachers/dialog.vue'

export default {
  name: 'Teachers',
  components: {DatatableHeader, TeacherDialog},
  data: () => ({
    translationKey: 'pages.teachers',
    filters: false,
    teachers: [],
    selected: [],
    teachersTotal: 0,
    school: '',
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    form: {
      number: '',
    },
    headers: [
      {
        text: 'id',
        align: 'center',
        sortable: false,
        value: 'id',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'name',
        align: 'center',
        sortable: false,
        value: 'name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'number',
        align: 'center',
        sortable: false,
        value: 'phone',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'status',
        align: 'center',
        sortable: false,
        value: 'active',
        sort: true,
        filter: {
          menu: false,
          type: 'select',
          translatable: true,
          translation_parent:'filtering.methods.',
          options: ['new', 'active', 'sent' , 'locked'],
        },
      },
      {
        text: 'school',
        align: 'center',
        sortable: false,
        value: 'school_name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },

      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),

  computed: {
    type() {
      return this.$route.meta.type;
    },
    rules() {
      const rules = {};
      rules.number = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.name`)]),
        v => /^(\+?964|0)?(7([456789])\d{8})$/.test(v) || 'This field only accept numbers',
      ];

      return rules;
    },
  },
  async mounted() {
    this.options = getOptions(this.$route, this.options);
    await this.fetch();

    // if (this.$route.params.id) {
    //   const response = await axios.get(`schools/${this.$route.params.id}`);
    //   this.school = response.data.data;
    // }
  },
  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options }).catch(() => {
        });
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const conditions = {
        ...this.options,
        teachers: 'teachers',
        filters,
      }

      if (this.$route.params.id) {
        conditions.school_id = this.$route.params.id;
      }
      const response = await axios.get(`/phoneNumber?${ObjectToQuery(conditions)}`);
      this.teachers = response.data.data.items
      this.teachersTotal = response.data.data.meta.total
      this.loading = false
    },
    // eslint-disable-next-line consistent-return
    async sentFunction(item) {
      if (item.sent === 1) {
        return 0;
      }
      await axios.post(`/phoneNumber/${item.id}`, { sent: 1 })
      await this.fetch()
      await toast.dispatch('success', this.$t('form.success.sent'))
      await statistics.dispatch('fetchApplicationStatistics');
    },
    async showNumbersSchool(id) {
      const path = `/students/${id}`
      if (this.$route.path !== path) {
        await this.$router.push(path)
      }
    },
    // eslint-disable-next-line consistent-return
    async activateFunction(item) {
      if (item.active === 1) {
        return 0;
      }
      await axios.post(`/phoneNumber/${item.id}`, { active: 1 })
      await this.fetch()
      await toast.dispatch('success', this.$t('form.success.activate'))
      await statistics.dispatch('fetchApplicationStatistics');
    },

    async exportExcel() {
      axios.get('phoneNumber/export', {
        responseType: 'arraybuffer',
        params: {
          teachers: 1,
        },
      }).then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
    },
    async exportExcelById(id) {
      axios
        .get('phoneNumber/export', {
          responseType: 'arraybuffer',
          params: {
            teachers: 1,
            school_id: id,
          },
        })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        })
    },

    async sentAll() {
      const payload = this.teachers.map(({ phone }) => phone)
      await axios.post('/phoneNumber/updateBulk', {
        data: payload,
        sent: 1,
      })
      await this.fetch()
      await toast.dispatch('success', this.$t('form.success.updateAll'))
    },
    async activeAll() {
      const payload = this.teachers.map(({ phone }) => phone)
      await axios.post('/phoneNumber/updateBulk', {
        data: payload,
        active: 1,
      })
      await this.fetch()
      await toast.dispatch('success', this.$t('form.success.updateAll'))
    },

    async sentSelected() {
      // eslint-disable-next-line camelcase
      const payload = this.selected.map(({ phone }) => phone)
      if (payload.length === 0) {
        return;
      }
      await axios.post('/phoneNumber/updateBulk', {
        data: payload,
        sent: 1,
      })
      this.selected = [];
      await this.fetch()
      await toast.dispatch('success', this.$t('form.success.updateAll'))
    },

    async activeSelected() {
      // eslint-disable-next-line camelcase
      const payload = this.selected.map(({ phone }) => phone)
      if (payload.length === 0) {
        return;
      }
      await axios.post('/phoneNumber/updateBulk', {
        data: payload,
        active: 1,
      })
      this.selected = [];
      await this.fetch()
      await toast.dispatch('success', this.$t('form.success.updateAll'))
    },

    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>
